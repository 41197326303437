
import { Button, Icons, Menu, Space } from "@pankod/refine-antd";
import { CanAccess, useNavigation, useResourceWithRoute } from "@pankod/refine-core";
import React, { useState } from "react";
import { RESOURCE_PATH } from "scripts/site";

export const EngagementSubMenu: React.FC<{
    engagementId: string,
    selectedResource?: string,
    renderAsMenu?: boolean,
}> = ({ engagementId, selectedResource = "", renderAsMenu = true }) => {
    const { push } = useNavigation();
    const resourceWithRoute = useResourceWithRoute();
    const [engId] = useState(engagementId);

    function navigateWithEngagementId(resourceName: string) {
        push(`/${resourceWithRoute(resourceName).route}?engagementId=${engId}`);
    }

    return (
        renderAsMenu ?
            <Menu mode="horizontal" selectedKeys={[selectedResource]} theme={"light"}>
                <CanAccess key={RESOURCE_PATH.ENGAGEMENTS} resource={RESOURCE_PATH.ENGAGEMENTS.toLowerCase()} action="show">
                    <Menu.Item icon={<Icons.InfoOutlined />}
                        onClick={() => {
                            push(`/${resourceWithRoute(RESOURCE_PATH.ENGAGEMENTS).route}/show/${engId}`);
                        }}>
                        Details
                    </Menu.Item>
                </CanAccess>
                <CanAccess key={RESOURCE_PATH.ASSESSMENT} resource={RESOURCE_PATH.ASSESSMENT.toLowerCase()} action="list">
                    <Menu.Item icon={<Icons.ContainerOutlined />}
                        onClick={() => {
                            navigateWithEngagementId(RESOURCE_PATH.ASSESSMENT);
                        }}>
                        Assessments
                    </Menu.Item>
                </CanAccess>
                <CanAccess key={RESOURCE_PATH.CONTRACT} resource={RESOURCE_PATH.CONTRACT.toLowerCase()} action="list">
                    <Menu.Item icon={<Icons.FileProtectOutlined />} onClick={() => {
                        navigateWithEngagementId(RESOURCE_PATH.CONTRACT);
                    }}>
                        Contracts
                    </Menu.Item>
                </CanAccess>
                <CanAccess key={RESOURCE_PATH.ASSOCIATEMAPPING} resource={RESOURCE_PATH.ASSOCIATEMAPPING.toLowerCase()} action="list">
                    <Menu.Item icon={<Icons.SolutionOutlined />} onClick={() => {
                        navigateWithEngagementId(RESOURCE_PATH.ASSOCIATEMAPPING);
                    }}>
                        Associates
                    </Menu.Item>
                </CanAccess>
            </Menu>
            :
            <Space>
                <CanAccess key={RESOURCE_PATH.ASSESSMENT} resource={RESOURCE_PATH.ASSESSMENT.toLowerCase()} action="list">
                    <Button size="middle" icon={<Icons.ContainerOutlined />}
                        onClick={() => {
                            navigateWithEngagementId(RESOURCE_PATH.ASSESSMENT);
                        }}>Assessments</Button>
                </CanAccess>
                <CanAccess key={RESOURCE_PATH.CONTRACT} resource={RESOURCE_PATH.CONTRACT.toLowerCase()} action="list">
                    <Button size="middle" icon={<Icons.FileProtectOutlined />}
                        onClick={() => {
                            navigateWithEngagementId(RESOURCE_PATH.CONTRACT);
                        }}>Contracts</Button>
                </CanAccess>
                <CanAccess key={RESOURCE_PATH.ASSOCIATEMAPPING} resource={RESOURCE_PATH.ASSOCIATEMAPPING.toLowerCase()} action="list">
                    <Button size="middle" icon={<Icons.SolutionOutlined />}
                        onClick={() => {
                            navigateWithEngagementId(RESOURCE_PATH.ASSOCIATEMAPPING);
                        }}>Associates</Button>
                </CanAccess>
            </Space>
    );
};